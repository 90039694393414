import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "card p-4" }
const _hoisted_2 = ["onClick", "data-kt-indicator"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Datatable = _resolveComponent("Datatable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_Datatable, {
      loading: _ctx.loading,
      "table-data": _ctx.tableData,
      "table-header": _ctx.tableHeader
    }, {
      "cell-pricePlanName": _withCtx(({ row: order }) => [
        _createTextVNode(_toDisplayString(order.pricePlanName), 1)
      ]),
      "cell-pricePlanType": _withCtx(({ row: order }) => [
        _createTextVNode(_toDisplayString(order.pricePlanType), 1)
      ]),
      "cell-status": _withCtx(({ row: order }) => [
        _createTextVNode(_toDisplayString(order.status), 1)
      ]),
      "cell-validTill": _withCtx(({ row: order }) => [
        _createTextVNode(_toDisplayString(order.validTill), 1)
      ]),
      "cell-actions": _withCtx(({ row: order }) => [
        (
						order.pricePlanType &&
						order.adyenSubscription &&
						(order.status === null ||
							!order.status.toLowerCase().startsWith('cancel')) &&
						(order.pricePlanType.toLowerCase() ===
							'SubscriptionMonth'.toLowerCase() ||
							order.pricePlanType.toLowerCase() ===
								'SubscriptionYear'.toLowerCase())
					)
          ? (_openBlock(), _createElementBlock("button", {
              key: 0,
              type: "button",
              onClick: ($event: any) => (_ctx.cancelSubscription(order.orderId)),
              class: "btn btn-lg btn-danger ms-5",
              "data-kt-indicator": _ctx.loading ? 'on' : null
            }, " Cancel ", 8, _hoisted_2))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["loading", "table-data", "table-header"])
  ]))
}