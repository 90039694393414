
import Datatable from '@/components/kt-datatable/KTDatatable.vue';
import { defineComponent, onMounted, ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import ApiService from '@/core/services/ApiService';

export default defineComponent({
	name: 'user-subscriptions',
	components: { Datatable },
	setup() {
		interface ISubscription {
			orderId: string;
			pricePlanId: string;
			pricePlanName: string;
			status: string;
			validTill: string;
			type: string;
		}
		const store = useStore();
		const route = useRoute();
		const loading = ref<boolean>(false);
		const tableData = ref<Array<ISubscription>>([]);
		const userId = ref(route.params.id);
		const subscriptionData = ref([]);
		const tableHeader = ref([
			{
				name: 'Plan',
				key: 'pricePlanName',
				sortable: true
			},
			{
				name: 'Type',
				key: 'pricePlanType',
				sortable: true
			},
			{
				name: 'Status',
				key: 'status',
				sortable: true
			},
			{
				name: 'Valid Till',
				key: 'validTill',
				sortable: true
			},
			{
				name: 'Actions',
				key: 'actions'
			}
		]);
		onMounted(() => {
			getSubscriptions();
		});

		const cancelSubscription = orderId => {
			loading.value = true;
			const reqData: any = { userId: route.params.id, orderId: orderId };

			ApiService.post(`User/cancelsubscriptionorder`, reqData)
				.then(res => {
					console.log(res.data);
				})
				.catch(e => {
					console.log(e);
				})
				.finally(() => {
					loading.value = false;
					getSubscriptions();
				});
		};

		const getSubscriptions = () => {
			loading.value = true;
			ApiService.get(`Order/GetOrdersForUser/${route.params.id}`)
				.then(({ data }) => {
					subscriptionData.value = data;
					tableData.value.splice(0, tableData.value.length, ...data);
				})
				.catch(({ response }) => {
					console.log('error', response);
				})
				.finally(() => {
					loading.value = false;
				});
		};

		return {
			userId,
			loading,
			tableHeader,
			tableData,
			subscriptionData,
			cancelSubscription
		};
	}
});
